

import { IntlTel_initPhoneInput } from 'javascript/lander/cf_utils'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class InputV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
    this.input = this.element.querySelector('.elInput');
    this.re = /^(([^<>()[\]\.,;:#%\s@"]+(\.[^<>()[\]\.,;:#%\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.input.getAttribute('name') === 'shipping_zip') {
      this.input.setAttribute('maxlength', '10');
    }
    this.addInputListeners()

    if (this.isStandalonePhoneNumberInput()) {
      this.setupPhoneNumberInput()
    }
  }

  update() {
    if (this.input.value) {
      this.element.classList.add('hasValue');
    } else {
      this.element.classList.remove('hasValue');
    }
  }

  isStandalonePhoneNumberInput() {
    return this.input.getAttribute('name') === 'phone_number' &&
      !$(this.input).closest('[data-page-element="Checkout/V1"]').length &&
      !$(this.input).closest('[data-page-element="Checkout/V2"]').length &&
      !$(this.input).closest('[data-page-element="Survey/V1').length
  }

  addInputListeners(){
    // Add class 'elInputFocused' when input is focused
    this.input.addEventListener('focus', () => {
      this.element.querySelector('[data-input-status-type]').innerHTML = ''
      this.element.classList.remove("elInputValid")
      this.element.classList.remove("elInputError")
      this.element.classList.remove("elInputWarning")
      this.element.classList.add('elInputFocused');
    })

    // Handle On blur event to validate input and add/remove class - 'hasValue'
    this.input.addEventListener('blur', () => {
      this.element.classList.remove('elInputFocused');
      this.update()
      
      if (this.input.getAttribute('name') === 'phone_number' && this.input.iti) {
        this.input.iti.setNumber(this.input.value)
      }
      if (this.input.classList.contains('required1') && this.element.matches('.elInputError')) {
        if (this.input.value === '') {
          return
        } else {
          if (this.input.getAttribute('name') === 'email') {
            const parsedEmail = $.trim(input.value);
            if (this.re.test(parsedEmail)) {
              this.element.classList.add('elInputValid')
              this.element.classList.remove('elInputError')
            }
          } else if (this.isStandalonePhoneNumberInput() && !window.intlTelInputUtils.isValidNumber(this.input.value)) {
            this.element.classList.remove('elInputValid')
            this.element.classList.add('elInputError')
          } else {
            this.element.classList.remove('elInputError')
            this.element.classList.add('elInputValid')
          }
        }
      }
    })
  }

  setupPhoneNumberInput() {
    // TODO: I think that cfVisitorData shouldn't be the first option to
    // retrieve the country. The primary source should be garlic inputs (?)
    // This only works correctly in production, because locally we
    // don't have the cloudflare headers in the request. So it always
    // falls back to 'us'
    IntlTel_initPhoneInput(this.input, {
      initialCountry: window.cfVisitorData?.country ?? 'us',
    })
  }



}

registerComponent('Input/V1', InputV1)
window["InputV1"] = InputV1

